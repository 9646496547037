<template>
  <div class="home">
   <Suspense>
    <template #default>
         <SearchEtablissement />
    </template>
    <template #fallback>
      Loading...
    </template>
  </Suspense>
 

  </div>
</template>

<script>
// @ is an alias to /src
import SearchEtablissement from '@/components/SearchEtablissement.vue'

export default {
  name: 'Home',
  components: {
    SearchEtablissement
  }
}
</script>

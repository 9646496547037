import axios from 'axios'

const API_URL = process.env.API_URL || 'https://etablissements-publics.api.gouv.fr/v3/departements/'
const apiClient = axios.create({
    baseURL: API_URL,
    withCredentials: false,
})


export default {
    async getEtablissements(departement, type) {
        let response = await apiClient.get("/" + departement + "/" + type)

        return response.data.features;

    },

}
<template>
  <div style="height: 75vh; width: 90vw;margin: auto;" >
   
    <l-map
      v-model="zoom"
      v-model:zoom="zoom"
      :center="center"

    >
      <l-tile-layer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      ></l-tile-layer>
      <l-control-layers />
      <l-marker :lat-lng="[elem.geometry.coordinates[1], elem.geometry.coordinates[0]]"  @moveend="log('moveend')" v-for="elem in data" v-bind:key="elem.properties.nom" >
        <l-tooltip>
          <strong>{{ elem.properties.nom }}</strong><br>
          {{ elem.properties.adresses[0].lignes[0] }} <br>
          {{ elem.properties.adresses[0].codePostal }} {{ elem.properties.adresses[0].commune }}<br>
          <span class="horaires"><strong>Horaires:</strong><br>
            <span v-for="horaire in elem.properties.horaires" v-bind:key="horaire"> 
              Du {{ horaire.du}} au {{ horaire.au }} : de {{ horaire.heures[0].de.split(":")[0] }}h{{ horaire.heures[0].de.split(":")[1] }} à {{ horaire.heures[0].a.split(":")[0] }}h{{ horaire.heures[0].a.split(":")[1] }}<br>
            </span>
            </span>

        </l-tooltip>
      </l-marker>

    </l-map>

  </div>
</template>
<script>
import {
  LMap,
  // LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LTooltip,
  // LPopup,
  // LPolyline,
  // LPolygon,
  // LRectangle,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  components: {
    LMap,
    // LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LTooltip,
    // LPopup,
    // LPolyline,
    // LPolygon,
    // LRectangle,
  },
  data() {
    return {
      zoom: 8,
  
    };
  },
  props: {
    data: Array,
    center: Array,
    //center: todo
  },
  computed :{
      
   },
  methods: {
    log(a) {
      console.log(a);
    },
    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
    
    
  },
};
</script>
<template>
  <div class="hello">
    <div class="field">
  <label class="label">Département</label>
  <div class="control">
    <input class="input ismid"  type="text" placeholder="Veuillez saisir votre numero de département" v-model="departement" list="departements"> 
  </div>
</div>
    <datalist id="departements">
        <option value="01">01 - Ain</option>
        <option value="02">02 - Aisne</option>
        <option value="03">03 - Allier</option>
        <option value="04">04 - Alpes-de-Haute-Provence</option>
        <option value="05">05 - Hautes-Alpes</option>
        <option value="06">06 - Alpes-Maritimes</option>
        <option value="07">07 - Ardèche</option>
        <option value="08">08 - Ardennes</option>
        <option value="09">09 - Ariège</option>
        <option value="10">10 - Aube</option>
        <option value="11">11 - Aude</option>
        <option value="12">12 - Aveyron</option>
        <option value="13">13 - Bouches-du-Rhône</option>
        <option value="14">14 - Calvados</option>
        <option value="15">15 - Cantal</option>
        <option value="16">16 - Charente</option>
        <option value="17">17 - Charente-Maritime</option>
        <option value="18">18 - Cher</option>
        <option value="19">19 - Corrèze</option>
        <option value="21">21 - Côte-d'Or</option>
        <option value="22">22 - Côtes-d'Armor</option>
        <option value="23">23 - Creuse</option>
        <option value="24">24 - Dordogne</option>
        <option value="25">25 - Doubs</option>
        <option value="26">26 - Drôme</option>
        <option value="27">27 - Eure</option>
        <option value="28">28 - Eure-et-Loir</option>
        <option value="29">29 - Finistère</option>
        <option value="2A">2A - Corse-du-Sud</option>
        <option value="2B">2B - Haute-Corse</option>
        <option value="30">30 - Gard</option>
        <option value="31">31 - Haute-Garonne</option>
        <option value="32">32 - Gers</option>
        <option value="33">33 - Gironde</option>
        <option value="34">34 - Hérault</option>
        <option value="35">35 - Ille-et-Vilaine</option>
        <option value="36">36 - Indre</option>
        <option value="37">37 - Indre-et-Loire</option>
        <option value="38">38 - Isère</option>
        <option value="39">39 - Jura</option>
        <option value="40">40 - Landes</option>
        <option value="41">41 - Loir-et-Cher</option>
        <option value="42">42 - Loire</option>
        <option value="43">43 - Haute-Loire</option>
        <option value="44">44 - Loire-Atlantique</option>
        <option value="45">45 - Loiret</option>
        <option value="46">46 - Lot</option>
        <option value="47">47 - Lot-et-Garonne</option>
        <option value="48">48 - Lozère</option>
        <option value="49">49 - Maine-et-Loire</option>
        <option value="50">50 - Manche</option>
        <option value="51">51 - Marne</option>
        <option value="52">52 - Haute-Marne</option>
        <option value="53">53 - Mayenne</option>
        <option value="54">54 - Meurthe-et-Moselle</option>
        <option value="55">55 - Meuse</option>
        <option value="56">56 - Morbihan</option>
        <option value="57">57 - Moselle</option>
        <option value="58">58 - Nièvre</option>
        <option value="59">59 - Nord</option>
        <option value="60">60 - Oise</option>
        <option value="61">61 - Orne</option>
        <option value="62">62 - Pas-de-Calais</option>
        <option value="63">63 - Puy-de-Dôme</option>
        <option value="64">64 - Pyrénées-Atlantiques</option>
        <option value="65">65 - Hautes-Pyrénées</option>
        <option value="66">66 - Pyrénées-Orientales</option>
        <option value="67">67 - Bas-Rhin</option>
        <option value="68">68 - Haut-Rhin</option>
        <option value="69">69 - Rhône</option>
        <option value="70">70 - Haute-Saône</option>
        <option value="71">71 - Saône-et-Loire</option>
        <option value="72">72 - Sarthe</option>
        <option value="73">73 - Savoie</option>
        <option value="74">74 - Haute-Savoie</option>
        <option value="75">75 - Paris</option>
        <option value="76">76 - Seine-Maritime</option>
        <option value="77">77 - Seine-et-Marne</option>
        <option value="78">78 - Yvelines</option>
        <option value="79">79 - Deux-Sèvres</option>
        <option value="80">80 - Somme</option>
        <option value="81">81 - Tarn</option>
        <option value="82">82 - Tarn-et-Garonne</option>
        <option value="83">83 - Var</option>
        <option value="84">84 - Vaucluse</option>
        <option value="85">85 - Vendée</option>
        <option value="86">86 - Vienne</option>
        <option value="87">87 - Haute-Vienne</option>
        <option value="88">88 - Vosges</option>
        <option value="89">89 - Yonne</option>
        <option value="90">90 - Territoire de Belfort</option>
        <option value="91">91 - Essonne</option>
        <option value="92">92 - Hauts-de-Seine</option>
        <option value="93">93 - Seine-Saint-Denis</option>
        <option value="94">94 - Val-de-Marne</option>
        <option value="95">95 - Val-d'Oise</option>
        <option value="971">971 - Guadeloupe</option>
        <option value="972">972 - Martinique</option>
        <option value="973">973 - Guyane</option>
        <option value="974">974 - La Réunion</option>
        <option value="976">976 - Mayotte</option>
    </datalist>
    <br>
        <div class="field">
  <label class="label">Type de l'organisme</label>
  <div class="control">
     <div class="select">
    <select v-model="organisme">
      <option value="accompagnement_personnes_agees">Plateforme d'accompagnement et de répit pour les aidants de personnes âgées</option>
      <option value="adil"> Information sur le logement (agenceAgence départementale pour l’information sur le logement (ADIL) départementale, Adil)</option>
      <option value="afpa"> Association nationale pour la formation professionnelle des adultes (AFPA), réseau local</option>
      <option value="anah"> Agence nationale de l’habitat (ANAH), réseau local</option>
      <option value="apec"> Association pour l’emploi des cadres (APEC)</option>
      <option value="apecita"> Association pour l'emploi des cadres, ingénieurs et techniciens de l'agriculture et de l'agroalimentaire (APECITA), réseau local</option>
      <option value="ars"> Agence régionale de santé (ARS)</option>
      <option value="ars_antenne"> Délégation territoriale de l'Agence régionale de santé</option>
      <option value="banque_de_france"> Banque de France, succursale</option>
      <option value="bav"> Bureau d'aide aux victimes</option>
      <option value="bsn"> Bureau ou centre du service national</option>
      <option value="caa"> Cour administrative d’appel</option>
      <option value="caf"> Caisse d’allocations familiales (CAF)</option>
      <option value="carsat"> Caisse d'assurance retraite et de la santé au travail (CARSAT)</option>
      <option value="ccas"> Centre communal d'action sociale</option>
      <option value="cci"> Chambre de commerce et d’industrie (CCI)</option>
      <option value="cdas"> Centre départemental d'action sociale</option>
      <option value="cddp"> Centre départemental de documentation pédagogique</option>
      <option value="cdg"> Centre de gestion de la fonction publique territoriale</option>
      <option value="centre_detention"> Centre de détention</option>
      <option value="centre_impots_fonciers"> Centre des impôts foncier et cadastre</option>
      <option value="centre_penitentiaire"> Centre pénitentiaire</option>
      <option value="centre_social"> Centre social</option>
      <option value="cesr"> Conseil économique, social et environnemental régional</option>
      <option value="cg"> Conseil départemental</option>
      <option value="chambre_agriculture"> Chambre d’agriculture</option>
      <option value="chambre_metier"> Chambre de métiers et de l’artisanat</option>
      <option value="cicas"> Centre d’information de conseil et d'accueil des salariés (CICAS)</option>
      <option value="cidf"> Centre d’information sur les droits des femmes et des familles (CIDFF)</option>
      <option value="cij"> Information jeunesse, réseau local</option>
      <option value="cio"> Centre d’information et d’orientation (CIO)</option>
      <option value="civi"> Commission d'indemnisation des victimes d'infraction</option>
      <option value="clic"> Point d'information local dédié aux personnes âgées</option>
      <option value="cnfpt"> Centre national de la fonction publique territoriale (CNFPT), réseau local</option>
      <option value="cnra"> Centre en route de la navigation aérienne</option>
      <option value="commissariat_police"> Commissariat de police</option>
      <option value="commission_conciliation"> Commission départementale de conciliation</option>
      <option value="conciliateur_fiscal"> Conciliateur fiscal</option>
      <option value="conseil_culture"> Conseil de la culture, de l’éducation et de l’environnement</option>
      <option value="cour_appel"> Cour d’appel</option>
      <option value="cpam"> Caisse primaire d’assurance maladie (CPAM)</option>
      <option value="cr"> Conseil régional</option>
      <option value="crc"> Chambre régionale ou territoriale des comptes</option>
      <option value="crdp"> Centre régional de documentation pédagogique</option>
      <option value="creps"> Centre régional d’éducation populaire et de sports (CREPS)</option>
      <option value="crfpn"> Centre ou délégation régionale de recrutement et de formation de la police nationale</option>
      <option value="crib"> Centre de ressources et d'information des bénévoles (CRIB)</option>
      <option value="crous"> CROUS et ses antennes</option>
      <option value="csl"> Centre de semi-liberté</option>
      <option value="dac"> Direction de l’aviation civile</option>
      <option value="dd_femmes"> Droit des femmes et égalité, mission départementale</option>
      <option value="dd_fip"> Direction départementale des finances publiques</option>
      <option value="ddcs"> Direction départementale de la cohésion sociale (DDCS)</option>
      <option value="ddcspp"> Direction départementale de la cohésion sociale et de la protection des populations (DDCSPP)</option>
      <option value="ddpjj"> Direction territoriale de la protection judiciaire de la jeunesse</option>
      <option value="ddpp"> Protection des populations (direction départementale, DDPP)</option>
      <option value="ddsp"> Direction départementale ou service de la sécurité publique</option>
      <option value="ddt"> Direction départementale des territoires -et de la mer- (DDT)</option>
      <option value="defenseur_droits"> Défenseur des droits</option>
      <option value="did_routes Direction"> interdépartementale des routes</option>
      <option value="dir_mer"> Direction interrégionale de la mer</option>
      <option value="dir_meteo"> Météo France, direction interrégionale</option>
      <option value="dir_pj"> Direction interrégionale de la police judiciaire</option>
      <option value="direccte"> Direction régionale des entreprises, de la concurrence, de la consommation, du travail et de l'emploi</option>
      <option value="direccte_ut"> Unité territoriale - Direction régionale des entreprises, de la concurrence, de la consommation, du travail et de l'emploi</option>
      <option value="dml"> Délégation à la mer et au littoral</option>
      <option value="dr_femmes"> Délégation régionale aux droits des femmes et à l’égalité</option>
      <option value="dr_fip"> Direction régionale des finances publiques</option>
      <option value="dr_insee"> Délégation régionale de l’INSEE</option>
      <option value="drac Direction"> régionale des affaires culturelles (DRAC)</option>
      <option value="draf Direction"> régionale de l'alimentation, de l’agriculture et de la forêt (DRAAF)</option>
      <option value="drddi"> Direction interrégionale et régionale des douanes</option>
      <option value="dreal"> Direction régionale de l’environnement, de l’aménagement et du logement (DREAL)</option>
      <option value="dreal_ut"> Unité territoriale - Direction régionale de l'environnement, de l'aménagement et du logement (DREAL)</option>
      <option value="driea"> Direction régionale et interdépartementale de l'équipement et de l'aménagement (DRIEA)</option>
      <option value="driea_ut"> Unité territoriale - Direction régionale et interdépartementale de l'équipement et de l'aménagement (DRIEA)</option>
      <option value="driee"> Direction régionale et interdépartementale de l'environnement et de l'énergie (DRIEE)</option>
      <option value="driee_ut"> Unité territoriale - Direction régionale et interdépartementale de l'environnement et de l'énergie (DRIEE)</option>
      <option value="drihl"> Direction régionale et interdépartementale de l'hébergement et du Hébergement et logement (direction logement (DRIHL)</option>
      <option value="drihl_ut"> Unité territoriale - Direction régionale et interdépartementale de l'hébergement et du logement (DRIHL)</option>
      <option value="drjscs"> Direction régionale de la jeunesse, des sports et de la cohésion sociale</option>
      <option value="dronisep"> Délégation régionale de l’ONISEP</option>
      <option value="drpjj"> Direction interdépartementale ou régionale de la protection judiciaire de la jeunesse</option>
      <option value="drrt"> Délégation régionale à la recherche et à la technologie</option>
      <option value="drsp"> Direction interrégionale des services pénitentiaires</option>
      <option value="dz_paf"> Direction zonale de la police aux frontières</option>
      <option value="edas"> Établissement départemental d'actions sociales</option>
      <option value="epci"> Intercommunalité</option>
      <option value="esm"> Etablissement spécialisé pour mineurs</option>
      <option value="fdapp"> Fédération départementale pour la pêche et la protection du milieu aquatique</option>
      <option value="fdc"> Fédération départementale des chasseurs</option>
      <option value="fongecif"> Fongecif</option>
      <option value="gendarmerie"> Brigade de gendarmerie</option>
      <option value="greta"> Greta</option>
      <option value="hypotheque"> Service de publicité foncière ex-conservation des hypothèques</option>
      <option value="inspection_academique"> Direction des services départementaux de l'Éducation nationale</option>
      <option value="maia"> Mission d’accueil et d’information des associations (MAIA)</option>
      <option value="mairie" selected> Mairie</option>
      <option value="mairie_com"> Mairie des collectivités d'outre-mer</option>
      <option value="mairie_mobile"> Mairie mobile de la ville de Paris</option>
      <option value="maison_arret"> Maison d'arrêt</option>
      <option value="maison_centrale"> Maison centrale</option>
      <option value="maison_handicapees"> Maison départementale des personnes handicapées (MDPH)</option>
      <option value="mds"> Maison départementale des solidarités</option>
      <option value="mission_locale"> Mission locale et Permanence d’accueil, d’information et d’orientation (PAIO)</option>
      <option value="mjd"> Maison de justice et du droit</option>
      <option value="msa"> Mutualité sociale agricole (MSA), réseau local</option>
      <option value="ofii"> Office français de l'immigration et de l'intégration (ex ANAEM), réseau local</option>
      <option value="onac"> Office national des anciens combattants (ONAC), réseau local</option>
      <option value="onf"> Direction régionale de l'Office national des forêts</option>
      <option value="paris_mairie"> Mairie de Paris, Hôtel de Ville</option>
      <option value="paris_mairie_arrondissement"> Mairie de Paris, mairie d'arrondissement</option>
      <option value="paris_ppp"> Préfecture de police de Paris</option>
      <option value="paris_ppp_antenne"> Préfecture de police de Paris, antenne d’arrondissement</option>
      <option value="paris_ppp_certificat_immatriculation"> Préfecture de police de Paris, certificat d'immatriculation</option>
      <option value="paris_ppp_gesvres"> Préfecture de police de Paris - Site central de Gesvres</option>
      <option value="paris_ppp_permis_conduire"> Préfecture de police de Paris, permis de conduire</option>
      <option value="permanence_juridique"> Permanence juridique</option>
      <option value="permanence_sociale"> Permanence sociale</option>
      <option value="pif"> Point info famille</option>
      <option value="pmi"> Centre de protection maternelle et infantile (PMI)</option>
      <option value="pole_emploi"> Pôle emploi</option>
      <option value="pp_marseille"> Préfecture de police des Bouches-du-Rhône</option>
      <option value="prefecture"> Préfecture</option>
      <option value="prefecture_greffe_associations"> Greffe des associations</option>
      <option value="prefecture_region"> Préfecture de région</option>
      <option value="prudhommes"> Conseil de prud’hommes</option>
      <option value="rectorat"> Rectorat</option>
      <option value="sdac"> Service territorial de l’architecture et du patrimoine</option>
      <option value="sdsei"> Services départementaux des solidarités et de l'insertion</option>
      <option value="service_navigation"> Service de la navigation</option>
      <option value="sgami"> Secrétariat pour l'administration du ministère de l'Intérieur (SGAMI)</option>
      <option value="sie"> Service des impôts des entreprises du Centre des finances publiques</option>
      <option value="sip"> Service des impôts des particuliers du Centre des finances publiques</option>
      <option value="sous_pref"> Sous-préfecture</option>
      <option value="spip"> Service pénitentiaire d'insertion et de probation</option>
      <option value="suio"> Service universitaire d'information et d'orientation</option>
      <option value="ta"> Tribunal administratif</option>
      <option value="te"> Tribunal pour enfants</option>
      <option value="tgi"> Tribunal de grande instance</option>
      <option value="ti"> Tribunal d’instance</option>
      <option value="tresorerie"> Trésorerie</option>
      <option value="tribunal_commerce"> Tribunal de commerce</option>
      <option value="urssaf"> Urssaf</option>
    </select>
    </div>
 </div>
</div>
<div class="buttons is-centered">
<span name="Rechercher" class="button is-link is-rounded is-outlined" @click="search()">Rechercher</span>
<span name="Exporter" class="button is-link is-rounded is-outlined"  @click="exporter()" v-if="etablissements.length>0">Exporter en CSV</span>
<span name="Carte"  class="button is-link is-rounded is-outlined" v-if="etablissements.length>0" @click="geolocaliser();">Géolocaliser</span>
</div>
<div class="is-centered" v-if="afficheCarte">
  <img src="@/assets/spinner.gif" v-if="loading">
  <Map :data="etablissements.filter((item) => {return item.geometry?.coordinates[1]})"  :center="centre" v-if="!loading"></Map>
</div>
<div id="etabList" v-if="etablissements.length>0">
<div class="is-centered">{{ etablissements.length }} établissements trouvés</div>
<div v-for="etab in etablissements" v-bind:key="etab.properties.nom" class="element">
<strong>{{ etab.properties.nom }}</strong> {{ etab.properties.adresses[0].lignes[0] }} {{ etab.properties.adresses[0].codePostal }} {{ etab.properties.adresses[0].commune }}
</div>
  </div>
    </div>
</template>

<script>
import { ref } from "vue";
import annuaireService from "@/services/annuaireService";
import Map from "@/components/map.vue";

export default {
  name: "Search",
  components: {
    Map,
  },
  async setup() {
    const departement = ref("");
    const organisme = ref("mairie");
    const etablissements = ref([]);
    const afficheCarte = ref(false);
    const loading = ref(true);
    const centre = ref([48.8534, 2.3488]);

    async function search() {
      if (organisme.value != "" && departement.value != "") {
        etablissements.value = [];
        etablissements.value = await annuaireService.getEtablissements(
          departement.value,
          organisme.value
        );
      } else {
        console.log("aucune recherche");
      }
    }

    function exporter() {
      let csvContent = "data:text/csv;charset=utf-8,";
      let csvArray = ["nom;adresse;codePostal;commune;"];

      for (var etab in etablissements.value) {
        csvArray.push(
          etablissements.value[etab].properties.nom +
            ";" +
            etablissements.value[etab].properties.adresses[0].lignes[0] +
            ';" ' +
            etablissements.value[etab].properties.adresses[0].codePostal +
            ' ";' +
            etablissements.value[etab].properties.adresses[0].commune +
            ";"
        );
      }

      csvContent += csvArray.join("\n");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    }
    async function geolocaliser() {
      
      afficheCarte.value = !afficheCarte.value;
      if (afficheCarte.value){
      loading.value = true;
      //centrer sur la prefecture
      var prefecture = await annuaireService.getEtablissements(
          departement.value,
          "prefecture"
        );
        if (prefecture){
          centre.value = [prefecture[0].geometry.coordinates[1], prefecture[0].geometry.coordinates[0]]
        }
      setTimeout(function(){
      loading.value = false;
      }, 2000); 
      }
    }
    return {
      search,
      exporter,
      departement,
      organisme,
      etablissements,
      geolocaliser,
      afficheCarte,
      loading,
      centre,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.element {
  text-align: left;
  padding-left: 20%;
}
.ismid {
  max-width: 50%;
}
</style>
